<template>
	<div class="single-view tool-redirects">
		<div class="tool-box">
			<h1>{{ ii('DNS_LOOKUP') }}</h1>
			<div>
				<div class="tool-input-box clearfix">
					<div class="col xs-12">
						<s-text-field
							v-model="app.query.host"
							prepend-icon="globe"
							ref="tf"
							:placeholder="ii('HOST')"
							:loading="isLoading"
							:error="error"
							@change="refreshQuery"
						/>
					</div>
					<div class="col xs-12">
						<s-multi-sel
							v-model="app.query.records"
							:items="recordsItems"
							:placeholder="ii('RECORDS')"
							@change="refreshQuery"
						/>
					</div>
				</div>
			</div>
			<div v-if="records" class="general-tbl-outer">
				<table class="general-tbl">
					<thead>
					<tr>
						<th>
							<s-tbl-sort v-model="app.query.sort" sort-key="" @input="refreshQuery">#</s-tbl-sort>
						</th>
						<th>
							<s-tbl-sort v-model="app.query.sort" sort-key="type" @input="refreshQuery">{{ ii('TYPE') }}</s-tbl-sort>
						</th>
						<th>
							<s-tbl-sort v-model="app.query.sort" sort-key="value" @input="refreshQuery">{{ ii('VALUE') }}</s-tbl-sort>
						</th>
						<th>
							<s-tbl-sort v-model="app.query.sort" sort-key="country" @input="refreshQuery">{{ ii('COUNTRY') }}</s-tbl-sort>
						</th>
						<th>
							<s-tbl-sort v-model="app.query.sort" sort-key="ttl" @input="refreshQuery">{{ ii('TTL') }}</s-tbl-sort>
						</th>
						<th>
							<s-tbl-sort v-model="app.query.sort" sort-key="pri" @input="refreshQuery">{{ ii('PRIORITY') }}</s-tbl-sort>
						</th>
					</tr>
					</thead>
					<tbody>
					<tr v-if="!records.length">
						<td colspan="100" class="text-center">{{ ii('NO_DATA') }}</td>
					</tr>
					<tr v-for="(record, i) in sortedRecords">
						<td class="text-center">
							{{ i + 1 }}
						</td>
						<td>
							{{ record.type }}
						</td>
						<td>
							{{ record.value }}
						</td>
						<td>
							<s-flag :cc="record.countryCode" />
							{{ record.country }}
						</td>
						<td>
							{{ record.ttl }}
						</td>
						<td>
							{{ record.pri }}
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLoading: false,
			error: null,
			recordsItems: [
				{key: 'A', val: 'A'},
				{key: 'AAAA', val: 'AAAA'},
				{key: 'MX', val: 'MX'},
				{key: 'CNAME', val: 'CNAME'},
				{key: 'HINFO', val: 'HINFO'},
				{key: 'CAA', val: 'CAA'},
				{key: 'NS', val: 'NS'},
				{key: 'PTR', val: 'PTR'},
				{key: 'SOA', val: 'SOA'},
				{key: 'TXT', val: 'TXT'},
				{key: 'SRV', val: 'SRV'},
				{key: 'NAPTR', val: 'NAPTR'},
				{key: 'A6', val: 'A6'}
			],
			records: null,
			reloadWatcher: null
		};
	},
	computed: {
		sortedRecords() {
			let key = this.app.query.sort || 'num';
			let dir = 1;
			if (key.charAt(0) === '-') {
				key = key.slice(1);
				dir = -1;
			}
			return this.records.sort((a, b) => {
				let aVal = a[key] || '';
				let bVal = b[key] || '';
				if (aVal > bVal) return dir;
				if (aVal < bVal) return -dir;
				if (a.num > b.num) return dir;
				if (a.num < b.num) return -dir;
				return 0;
			});
		}
	},
	methods: {
		async loadInfo() {
			this.records = null;

			if (!this.app.query.host) return;

			this.isLoading = true;
			this.records = await this.get('Domain.getDns', this.app.query);
			this.isLoading = false;
		}
	},
	watch: {
		$route() {
			this.reloadWatcher = this.app.query.host + ' ' + this.app.query.records;
		},
		reloadWatcher() {
			this.loadInfo();
		}
	},
	mounted() {
		this.loadInfo();
		if (!this.app.isMob) {
			this.$refs.tf.focus();
		}
	}
}
</script>